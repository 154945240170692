.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden;
    backdrop-filter: brightness(0.8) blur(6px);
}

.modal-overlay.loaded {
}

.modal-content {
    background: #313840;
    border: 1px solid #454e5a;
    text-align: left;
    padding: 20px 60px;
    border-radius: 8px;
    position: relative;
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto;
    font-size: 10pt;
}

.modal-content h3 {
    text-align: center;
    font-size: 14pt;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: white;
    cursor: pointer;
}

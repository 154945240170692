.pong-animation-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes pong-bounce {
    0%, 100% {
        transform: translateX(-50px);
    }
    50% {
        transform: translateX(50px);
    }
}

.ball {
    fill: #3498db; /* Ball color */
    animation: pong-bounce 2s infinite;
}

.network-selector {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
}

.network-selector select {
    background-color: #f6851b;
    color: white;
    border: none;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, transform 0.3s;
}

.network-selector select:hover {
    background-color: #e37400;
}

.network-selector select:active {
    background-color: #cc6600;
}

.network-selector select option {
    background-color: white;
    color: black;
}
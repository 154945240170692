.vaults {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
    max-width: calc(100vw - 40px);
}

.vault {
    flex: 0 1 300px; /* Flex-grow: 0, Flex-shrink: 1, Flex-basis: 400px */
    max-width: 300px;
    width: 100%;
    border: 1px solid #cc6600;
    padding: 0 12px 18px 12px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    transition: backdrop-filter 0.3s;
}

.vault:hover {
    backdrop-filter: brightness(1.2);
}

.vault p {
    font-size: 8pt;
    margin: 24px 0 0 0;
}

.vault h3 {
    font-size: 12pt;
}

.vault .network-logo {
    width: 20px;
    height: 20px;
}

.vault .network-logo svg {
    width: 100%;
}
.tabs-container {
    width: 100%;
    margin: 20px 0;
}

.tabs {
    display: flex;
    border-bottom: 1px solid #ccc;
}

.tab {
    padding: 10px 20px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-bottom: none;
    background-color: #282c34;
    transition: background-color 0.3s;
}

.tab.active {
    background-color: #313540;
    border-top: 2px solid #3498db;
    border-bottom: 2px solid white;
}

.tab:hover {
    background-color: #e0e0e0;
}

.tab-content {
    border: 1px solid #ccc;
}
.app-header {
    height: 80px;
    width: calc(100vw - 80px);
    padding: 20px 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.app-logo {
    pointer-events: none;
}

.wallet-section {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.wallet-address {
    font-size: 8pt;
}

.metamask-button {
    background-color: #f6851b;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, transform 0.3s;
}

.metamask-button:hover {
    background-color: #e37400;
    transform: translateY(-2px);
}

.metamask-button:active {
    background-color: #cc6600;
    transform: translateY(0);
}